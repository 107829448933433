exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  const mainRegexString = process.env.GATSBY_MAIN_REGEX;

  // prevLocationがnullの場合は何もしない
  if (prevLocation === null) return;

  // 文字列から正規表現に変更
  const mainRegex = mainRegexString ? new RegExp(mainRegexString) : null;

  const isPrevMain = mainRegex.test(prevLocation.pathname);
  const isCurrentMain = mainRegex.test(location.pathname);

  // サブからメイン、またはメインからサブへの遷移の場合
  if (isPrevMain !== isCurrentMain) {
    const newUrl = 'https://recruit-holdings.com' + location.pathname;
    window.location.href = newUrl;
  }
};
