exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-covid-19-tsx": () => import("./../../../src/pages/covid19.tsx" /* webpackChunkName: "component---src-pages-covid-19-tsx" */),
  "component---src-pages-ir-business-overview-tsx": () => import("./../../../src/pages/ir/business-overview.tsx" /* webpackChunkName: "component---src-pages-ir-business-overview-tsx" */),
  "component---src-pages-ir-resources-faq-tsx": () => import("./../../../src/pages/ir/resources/faq.tsx" /* webpackChunkName: "component---src-pages-ir-resources-faq-tsx" */),
  "component---src-pages-ir-resources-market-research-data-tsx": () => import("./../../../src/pages/ir/resources/market-research-data.tsx" /* webpackChunkName: "component---src-pages-ir-resources-market-research-data-tsx" */),
  "component---src-pages-ir-resources-public-notice-tsx": () => import("./../../../src/pages/ir/resources/public-notice.tsx" /* webpackChunkName: "component---src-pages-ir-resources-public-notice-tsx" */),
  "component---src-pages-ir-resources-stock-quotations-tsx": () => import("./../../../src/pages/ir/resources/stock-quotations.tsx" /* webpackChunkName: "component---src-pages-ir-resources-stock-quotations-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-ir-events-tsx": () => import("./../../../src/templates/ir/events.tsx" /* webpackChunkName: "component---src-templates-ir-events-tsx" */),
  "component---src-templates-ir-financials-tsx": () => import("./../../../src/templates/ir/financials.tsx" /* webpackChunkName: "component---src-templates-ir-financials-tsx" */),
  "component---src-templates-ir-index-tsx": () => import("./../../../src/templates/ir/index.tsx" /* webpackChunkName: "component---src-templates-ir-index-tsx" */),
  "component---src-templates-ir-post-tsx": () => import("./../../../src/templates/ir/post.tsx" /* webpackChunkName: "component---src-templates-ir-post-tsx" */),
  "component---src-templates-newsroom-holdings-select-query-category-tsx": () => import("./../../../src/templates/newsroom/holdings-select-query-category.tsx" /* webpackChunkName: "component---src-templates-newsroom-holdings-select-query-category-tsx" */),
  "component---src-templates-newsroom-holdings-select-query-yearly-tsx": () => import("./../../../src/templates/newsroom/holdings-select-query-yearly.tsx" /* webpackChunkName: "component---src-templates-newsroom-holdings-select-query-yearly-tsx" */),
  "component---src-templates-newsroom-holdings-tsx": () => import("./../../../src/templates/newsroom/holdings.tsx" /* webpackChunkName: "component---src-templates-newsroom-holdings-tsx" */),
  "component---src-templates-newsroom-post-tsx": () => import("./../../../src/templates/newsroom/post.tsx" /* webpackChunkName: "component---src-templates-newsroom-post-tsx" */),
  "component---src-templates-newsroom-search-tsx": () => import("./../../../src/templates/newsroom/search.tsx" /* webpackChunkName: "component---src-templates-newsroom-search-tsx" */)
}

